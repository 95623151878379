import Vue from 'vue'
import { AddressEdit,
    Cell,
    CellGroup,
    Area,
    Button,
    Calendar,
    Checkbox,
    CheckboxGroup,
    CountDown,
    Card,
    DatetimePicker,
    Dialog,
    DropdownItem,
    Form,
    Field,
    ImagePreview,
    Lazyload,
    List,
    Locale,
	Loading,
    Notify,
	Overlay,
    Picker,
    SubmitBar ,
    Sku,
    Swipe,
    SwipeCell,
    Tab,
    Tabs,
    Toast,
    Uploader,
    Panel,
    Radio,
    RadioGroup,
    Image ,
    Collapse, CollapseItem,
    DropdownMenu,
  Icon,
  Col,Row,SwipeItem} from 'vant' 

  
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(SwipeItem)
Vue.use(Image)
Vue.use(Panel)
Vue.use(Swipe)
Vue.use(Col)
Vue.use(Row)
Vue.use(Icon)
Vue.use(AddressEdit)
Vue.use(Area)
Vue.use(Button)
Vue.use(Calendar)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(CountDown)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Card)
Vue.use(DatetimePicker)
Vue.use(Dialog)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Form)
Vue.use(Field)
Vue.use(ImagePreview)
Vue.use(Lazyload)
Vue.use(Loading)
Vue.use(List)
Vue.use(Locale)
Vue.use(Notify)
Vue.use(Overlay)
Vue.use(Picker)
Vue.use(Sku)
Vue.use(Swipe)
Vue.use(SwipeCell)
Vue.use(SubmitBar)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Toast)
Vue.use(Uploader)