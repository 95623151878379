import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router =  new Router({
	mode: 'history',
	linkActiveClass: 'selected',
	routes: [{
			path: '/',
			redirect: '/doctor'
		},
		{
			path: '/doctor',
			name: 'doctor',
			component: () => import("@/pages/bind/doctor.vue"),
			meta: {
				title: '实体卡绑定'
			}
		},
		{
			path: '/info',
			name: 'info',
			component: () => import("@/pages/bind/info.vue"),
			meta: {
				title: '填写个人资料'
			}
		},
		{
			path: '/explain',
			name: 'explain',
			component: () => import("@/pages/bind/explain.vue"),
			meta: {
				title: '使用说明'
			}
		},
		{
			path: '/login',
			name: 'login',
			component: () => import("@/pages/center/login.vue"),
			meta: {
				title: '个人中心'
			}
		},
		{
			path: '/center',
			name: 'center',
			component: () => import("@/pages/center/center.vue"),
			meta: {
				title: '卡片明细'
			}
		}
	]

})

// router.beforeEach((to, from, next) => {
// 	if (to.name === 'center') {
// 		const _phone = localStorage.getItem('phone')
// 		if (_phone) {
// 			next()
// 		} else {
// 			router.push({
// 				path: '/login'
// 			})
//
// 		}
// 		return
// 	}
// 	next()
// })

export default router
