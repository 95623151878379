<template>
    <!--  加载地图组件-->
  <!-- <AMap :projects="mapData" type="buildings" /> -->
  <router-view></router-view>
</template>

<script>

import axios from 'axios';
// import AMap from './components/AMap.vue'

export default {
  name: 'App',
  // components: {
  //   AMap
  // },
  data() {
    return {
      projectId: null,
      token:null,
      mapData: []
    }
  },
  created() {
    this.projectId = this.getUrlParam('projectId');
    this.token = this.getUrlParam('X-Access-Token');
    //this.getMapData()
  },
  mounted() {

  },
  methods:{
    getUrlParam(name) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    },

    getMapData(){
      // 发送 GET 请求，传递查询参数和 Token
      axios.get('http://114.115.158.36:8291/sys/map/project/getProjectBuildingList', {
        params: {
          projectId: this.projectId
        },
        headers: {
          'X-Access-Token': this.token
        }
      })
      .then(response => {
        console.log(response.data);
          if(response.data.success){
            this.mapData = [response.data.result];
          }else{
            this.mapData = [];
          }
      })
      .catch(error => {
          console.error(error);
          this.mapData = [];
      });
    },
  }
}
</script>

<style>
  .flex {
    display: flex;
  }
  .al-c {
    align-items: center;
  }
  .ju-c {
    justify-content: center;
  }
  .ju-b {
    justify-content: space-between;
  }
</style>
