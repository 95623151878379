import Vue from 'vue'
import App from './App.vue'
import router from "./router/router.js"
Vue.config.productionTip = false
import './config/vant'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/styles/global.css'

Vue.prototype.$primaryColor = '#98D66B'
 
Vue.use(Vant);
// Vue.use(Vant);

// 监听窗口大小变化
window.addEventListener('resize', function () {
	setRem()
})

// 设置rem函数
function setRem() {
	// 320按照设计稿的尺寸换算为10rem
	const width = document.documentElement.clientWidth || document.body.clientWidth
	const rem = width / 320 * 9
	document.documentElement.style.fontSize = `${rem}px`
}

// 初始化
setRem()

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')